// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/image/iconmonstr-arrow-47_under.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".profile_icon[data-v-32814f80]::after {\n  content: '';\n  display: inline-block;\n  width: 15px;\n  height: 10px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-size: contain;\n  vertical-align: initial;\n  margin-left: 5px;\n  margin-bottom: 1px;\n}\n.profile_img[data-v-32814f80] {\n  width: 10px;\n  height: 10px;\n}\n", ""]);
// Exports
module.exports = exports;
