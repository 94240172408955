var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "modal",
        {
          staticClass: "w-6/12 sm:w-full",
          attrs: {
            name: "updatePasswordModal",
            height: "auto",
            "max-height": 550,
            scrollable: true,
            adaptive: true,
          },
          on: {
            "before-close": function ($event) {
              return _vm.resetPass()
            },
          },
        },
        [
          _c("div", { staticClass: "relative" }, [
            _c(
              "button",
              {
                staticClass:
                  "close_btn h-3 w-3 float-left absolute sm:h-5 sm:w-5 sm:m-2",
              },
              [
                _c("img", {
                  attrs: { src: require("@/assets/image/close_icon_gray.svg") },
                  on: {
                    click: function ($event) {
                      return _vm.$modal.hide("updatePasswordModal")
                    },
                  },
                }),
              ]
            ),
            _c("div", { staticClass: "w-full h-12" }, [
              _c(
                "svg",
                {
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    viewBox: "0 0 100 8",
                    preserveAspectRatio: "none",
                  },
                },
                [
                  _c("polygon", {
                    attrs: {
                      points: "100,6.4 0,0.8 0,0 100,0",
                      fill: "#fff000",
                    },
                  }),
                ]
              ),
            ]),
          ]),
          _c("div", { staticClass: "w-10/12 mx-auto mb-4" }, [
            _c("h2", { staticClass: "uppercase text-center mb-4" }, [
              _vm._v(_vm._s(_vm.$t("components.change_password.title"))),
            ]),
            _c("p", { staticClass: "text-center" }, [
              _vm._v(_vm._s(_vm.$t("components.change_password.message"))),
            ]),
          ]),
          _c(
            "ValidationObserver",
            {
              ref: "updatePassForm",
              staticClass: "w-10/12 mx-auto",
              attrs: { tag: "div" },
            },
            [
              _c(
                "div",
                { staticClass: "mb-4 w-full" },
                [
                  _c("FlInput", {
                    staticClass: "mb-4",
                    attrs: {
                      type: "password",
                      label: "password",
                      sublabel: _vm.$t("components.change_password.caution"),
                      rules: { required: true, min: 8 },
                      name: _vm.$t("commons.fields.password"),
                    },
                    model: {
                      value: _vm.password,
                      callback: function ($$v) {
                        _vm.password = $$v
                      },
                      expression: "password",
                    },
                  }),
                  _vm.showMFA
                    ? _c(
                        "FlCheckbox",
                        { attrs: { value: _vm.mfa, label: "MFA" } },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("pages.users.change_password.mfa"))
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "w-7/12 mx-auto mb-4" },
                [
                  _c(
                    "LinkButton",
                    {
                      attrs: { classList: "px-2 py-2 text-center" },
                      on: { clickButton: _vm.updatePass },
                    },
                    [_vm._v("UPDATE")]
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "w-7/12 mx-auto mb-4" }, [
                _c("p", { staticClass: "text-xs text-red-600" }, [
                  _vm._v(_vm._s(_vm.errorMessage)),
                ]),
              ]),
            ]
          ),
          _c("div", { staticClass: "w-full footer-triangle" }, [
            _c(
              "svg",
              {
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  viewBox: "0 0 100 8",
                  preserveAspectRatio: "none",
                },
              },
              [
                _c("polygon", {
                  attrs: { points: "100,8 0,8 0,1.8 100,7", fill: "#000" },
                }),
              ]
            ),
          ]),
        ],
        1
      ),
      _c("NoticeModal", {
        attrs: {
          modalName: "successUpdate",
          contents: "パスワードの更新が完了しました。",
          buttons: ["OK"],
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }