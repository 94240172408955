var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "modal",
        {
          staticClass: "w-4/12 sm:w-full m-3",
          attrs: {
            name: "loginModal",
            height: "auto",
            "max-height": 550,
            scrollable: true,
            adaptive: true,
          },
          on: { opened: _vm.loginShowEvent, "before-close": _vm.beforeClose },
        },
        [
          _c("div", { staticClass: "relative" }, [
            _c(
              "button",
              {
                staticClass:
                  "close_btn h-3 w-3 absolute sm:h-5 sm:w-5 sm:m-2 top-2 right-2",
              },
              [
                _c("img", {
                  attrs: { src: require("@/assets/image/close_icon_gray.svg") },
                  on: {
                    click: function ($event) {
                      return _vm.loginHide()
                    },
                  },
                }),
              ]
            ),
          ]),
          _c(
            "ValidationObserver",
            {
              ref: "loginForm",
              staticClass: "w-full mx-auto m-4",
              attrs: { tag: "div" },
            },
            [
              _c(
                "h1",
                { staticClass: "uppercase text-center font-normal text-2xl" },
                [_vm._v("LOGIN")]
              ),
              _c(
                "div",
                { staticClass: "w-full sm:w-7/12 mx-auto mb-2" },
                [
                  _c("FlInput", {
                    ref: "email",
                    attrs: {
                      type: "email",
                      label: _vm.$t("pages.users.login.email"),
                      rules: { required: true, email: true },
                      disabled: _vm.disableLoginFields,
                    },
                    on: {
                      onEnter: function ($event) {
                        _vm.email.length && _vm.password.length
                          ? _vm.login()
                          : _vm.$refs.password.focus()
                      },
                    },
                    model: {
                      value: _vm.email,
                      callback: function ($$v) {
                        _vm.email = $$v
                      },
                      expression: "email",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "w-full sm:w-7/12 mx-auto mb-2" },
                [
                  _c("FlInput", {
                    ref: "password",
                    attrs: {
                      type: "password",
                      label: _vm.$t("pages.users.login.password"),
                      rules: { required: true, min: 8 },
                      disabled: _vm.disableLoginFields,
                    },
                    on: {
                      onEnter: function ($event) {
                        _vm.email.length && _vm.password.length
                          ? _vm.login()
                          : _vm.$refs.email.focus()
                      },
                    },
                    model: {
                      value: _vm.password,
                      callback: function ($$v) {
                        _vm.password = $$v
                      },
                      expression: "password",
                    },
                  }),
                ],
                1
              ),
              _vm.disableLoginFields
                ? _c(
                    "div",
                    { staticClass: "w-full sm:w-7/12 mx-auto mb-2" },
                    [
                      _c("FlInput", {
                        ref: "mfa",
                        attrs: {
                          type: "text",
                          label: _vm.$t("pages.users.login.mfa_label"),
                          sublabel: _vm.$t("pages.users.login.mfa_explanation"),
                          rules: { required: true, min: 6 },
                        },
                        on: { onEnter: _vm.sendMFA },
                        model: {
                          value: _vm.mfa,
                          callback: function ($$v) {
                            _vm.mfa = $$v
                          },
                          expression: "mfa",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c("div", { staticClass: "w-full sm:w-7/12 mx-auto" }, [
                _c("p", { staticClass: "text-xs text-red-600" }, [
                  _vm._v(_vm._s(_vm.errorMessage)),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "w-full sm:w-7/12 mx-auto my-4" },
                [
                  _c(
                    "LinkButton",
                    {
                      attrs: {
                        classList: "px-2 py-2 text-center",
                        processing: _vm.processing,
                      },
                      on: {
                        clickButton: function ($event) {
                          _vm.disableLoginFields ? _vm.sendMFA() : _vm.login()
                        },
                      },
                    },
                    [
                      !_vm.disableLoginFields
                        ? _c("p", [
                            _vm._v(
                              _vm._s(_vm.$t("pages.users.login.login_title"))
                            ),
                          ])
                        : _c("p", [
                            _vm._v(
                              _vm._s(_vm.$t("pages.users.login.send_mfa"))
                            ),
                          ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "w-full sm:w-7/12 mx-auto text-center font-bold mb-4 hover:bg-gray-300",
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "font-bold underline",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.clickLostPass($event)
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("pages.users.login.lost_pass")) +
                          " "
                      ),
                    ]
                  ),
                ]
              ),
              _c("div", { staticClass: "w-full h-10" }),
              _c(
                "div",
                {
                  staticClass: "w-full sm:w-7/12 mx-auto mb-6",
                  on: {
                    click: function ($event) {
                      return _vm.loginHide()
                    },
                  },
                },
                [
                  _c(
                    "LinkButton",
                    {
                      attrs: {
                        link: "/ch/register",
                        classList: "px-2 py-2 bg-light-yellow",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("pages.tops.registration.ch")))]
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "w-full sm:w-7/12 mx-auto mb-6",
                  on: {
                    click: function ($event) {
                      return _vm.loginHide()
                    },
                  },
                },
                [
                  _c(
                    "LinkButton",
                    {
                      attrs: {
                        link: "/vod/register",
                        classList: "px-2 py-2 bg-light-yellow",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("pages.tops.registration.vod")))]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }