var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container mx-auto" }, [
    _c(
      "div",
      { staticClass: "grid grid-cols-2" },
      [
        _vm._l(_vm.value, function (movie, key) {
          return [
            key < 2
              ? _c(
                  "button",
                  {
                    key: key,
                    staticClass: "col-span-1 relative pt-16_9 h-0",
                    on: {
                      click: function ($event) {
                        return _vm.redirectMovie(movie)
                      },
                    },
                  },
                  [
                    movie && movie.featured_images.length
                      ? _c("img", {
                          staticClass:
                            "display-block absolute inset-0 bg-gray-300 w-full h-full object-cover object-center",
                          attrs: { src: movie.featured_images[0].file.url },
                        })
                      : _c("img", {
                          staticClass: "w-full object-cover object-center",
                          attrs: {
                            src: require("@/assets/image/loading_16_9.png"),
                          },
                        }),
                    _c(
                      "div",
                      {
                        staticClass:
                          "absolute text-left text-white font-bold leading-none left-0 bottom-0 pl-2 pb-2",
                      },
                      [
                        _c(
                          "p",
                          {
                            staticClass:
                              "text-xs pb-1 text-shadow sm:text-xl sm:pb-2",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  movie.translations[_vm.$i18n.locale].film_name
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "p",
                          { staticClass: "text-xxs text-shadow sm:text-lg" },
                          [
                            _vm._v(
                              _vm._s(
                                movie.translations[_vm.$i18n.locale].director
                              )
                            ),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "absolute text-right text-white right-0 top-0",
                      },
                      [
                        _c(
                          "p",
                          {
                            staticClass:
                              "text-xxs pr-1 pt-0 text-shadow sm:pr-2 sm:pt-2",
                          },
                          [_vm._v(_vm._s(movie.copyright))]
                        ),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
          ]
        }),
      ],
      2
    ),
    _c(
      "div",
      { staticClass: "grid grid-cols-3" },
      [
        _vm._l(_vm.value, function (movie, key) {
          return [
            key >= 2 && key < 5
              ? _c(
                  "button",
                  {
                    key: key,
                    staticClass: "col-span-1 relative pt-16_9 h-0",
                    on: {
                      click: function ($event) {
                        return _vm.redirectMovie(movie)
                      },
                    },
                  },
                  [
                    movie && movie.featured_images.length
                      ? _c("img", {
                          staticClass:
                            "display-block absolute inset-0 bg-gray-300 w-full h-full object-cover object-center",
                          attrs: { src: movie.featured_images[0].file.url },
                        })
                      : _c("img", {
                          staticClass:
                            "display-block absolute inset-0 bg-gray-300 w-full h-full object-cover object-center",
                          attrs: {
                            src: require("@/assets/image/loading_16_9.png"),
                          },
                        }),
                    _c(
                      "div",
                      {
                        staticClass:
                          "absolute text-left text-white font-bold leading-none left-0 bottom-0 pl-2 pb-2",
                      },
                      [
                        _c(
                          "p",
                          {
                            staticClass:
                              "text-xs pb-1 text-shadow sm:text-xl sm:pb-2",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  movie.translations[_vm.$i18n.locale].film_name
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "p",
                          { staticClass: "text-xxs text-shadow sm:text-lg" },
                          [
                            _vm._v(
                              _vm._s(
                                movie.translations[_vm.$i18n.locale].director
                              )
                            ),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "absolute text-right text-white right-0 top-0",
                      },
                      [
                        _c(
                          "p",
                          {
                            staticClass:
                              "text-xxs pr-1 pt-0 text-shadow sm:pr-2 sm:pt-2",
                          },
                          [_vm._v(_vm._s(movie.copyright))]
                        ),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
          ]
        }),
      ],
      2
    ),
    _c("div", { staticClass: "w-full bg-black text-white" }, [
      _c("p", { staticClass: "px-1 py-2 text-xs text-center" }, [
        _vm._v(
          " " + _vm._s(_vm.$t("pages.tops.objectives_of_this_content.msg1"))
        ),
        _c("br", { staticClass: "inline sm:hidden" }),
        _vm._v(
          " " + _vm._s(_vm.$t("pages.tops.objectives_of_this_content.msg2"))
        ),
        _c("br", { staticClass: "inline sm:hidden" }),
        _vm._v(
          " " +
            _vm._s(_vm.$t("pages.tops.objectives_of_this_content.msg3")) +
            " "
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }