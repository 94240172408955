var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("FlInputWrapper", {
    attrs: {
      label: _vm.label,
      sublabel: _vm.sublabel,
      rules: _vm.rules,
      immediate: _vm.immediate,
      name: _vm.name,
      showError: _vm.showError,
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (ref) {
          var errors = ref.errors
          return [
            _vm.modifiedType === "checkbox"
              ? _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.mutableValue,
                      expression: "mutableValue",
                    },
                  ],
                  key: _vm.inputKey,
                  ref: "input",
                  staticClass: "w-full p-2 h-10 bg-white border rounded",
                  class: [
                    errors.length && _vm.showError
                      ? "bg-red-200 border-red-600"
                      : "border-brown-grey",
                    _vm.disabled ? "bg-light-grey" : "",
                  ],
                  attrs: {
                    name: _vm.name,
                    disabled: _vm.disabled,
                    placeholder: _vm.placeholder,
                    type: "checkbox",
                  },
                  domProps: {
                    checked: Array.isArray(_vm.mutableValue)
                      ? _vm._i(_vm.mutableValue, null) > -1
                      : _vm.mutableValue,
                  },
                  on: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.$emit("onEnter")
                    },
                    change: function ($event) {
                      var $$a = _vm.mutableValue,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.mutableValue = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.mutableValue = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.mutableValue = $$c
                      }
                    },
                  },
                })
              : _vm.modifiedType === "radio"
              ? _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.mutableValue,
                      expression: "mutableValue",
                    },
                  ],
                  key: _vm.inputKey,
                  ref: "input",
                  staticClass: "w-full p-2 h-10 bg-white border rounded",
                  class: [
                    errors.length && _vm.showError
                      ? "bg-red-200 border-red-600"
                      : "border-brown-grey",
                    _vm.disabled ? "bg-light-grey" : "",
                  ],
                  attrs: {
                    name: _vm.name,
                    disabled: _vm.disabled,
                    placeholder: _vm.placeholder,
                    type: "radio",
                  },
                  domProps: { checked: _vm._q(_vm.mutableValue, null) },
                  on: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.$emit("onEnter")
                    },
                    change: function ($event) {
                      _vm.mutableValue = null
                    },
                  },
                })
              : _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.mutableValue,
                      expression: "mutableValue",
                    },
                  ],
                  key: _vm.inputKey,
                  ref: "input",
                  staticClass: "w-full p-2 h-10 bg-white border rounded",
                  class: [
                    errors.length && _vm.showError
                      ? "bg-red-200 border-red-600"
                      : "border-brown-grey",
                    _vm.disabled ? "bg-light-grey" : "",
                  ],
                  attrs: {
                    name: _vm.name,
                    disabled: _vm.disabled,
                    placeholder: _vm.placeholder,
                    type: _vm.modifiedType,
                  },
                  domProps: { value: _vm.mutableValue },
                  on: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.$emit("onEnter")
                    },
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.mutableValue = $event.target.value
                    },
                  },
                }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }