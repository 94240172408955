var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("TopMovies", {
        model: {
          value: _vm.filmMovies,
          callback: function ($$v) {
            _vm.filmMovies = $$v
          },
          expression: "filmMovies",
        },
      }),
      _c("div", { staticClass: "container mx-auto" }),
      _c("div", { staticClass: "container mx-auto" }, [
        _c("div", { staticClass: "border-b-2 border-gray-400" }),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  _vm.loginUserRole === "" || _vm.loginUserRole === "manager",
                expression:
                  "loginUserRole === '' || loginUserRole === 'manager'",
              },
            ],
            staticClass: "grid grid-cols-1 sm:grid-cols-12 sm:gap-x-6",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "col-span-1 sm:col-span-5 sm:col-start-2 mx-3 my-4 sm:mb-0 sm:mx-0",
              },
              [
                _c(
                  "LinkButton",
                  {
                    attrs: {
                      link: "/ch/register",
                      classList: "px-2 py-4 text-lg sm:text-md",
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("pages.tops.registration.ch")))]
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "col-span-1 sm:col-span-5 sm:col-start-7 mx-3 mt-4 sm:mx-0",
              },
              [
                _c(
                  "LinkButton",
                  {
                    attrs: {
                      link: "/vod/register",
                      classList: "px-2 py-4 text-lg sm:text-md",
                    },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("pages.tops.registration.vod")) + " "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]
        ),
      ]),
      _c("div", { staticClass: "container mx-auto" }, [
        _c(
          "div",
          {
            staticClass:
              "flex flex-col sm:flex-row grid grid-cols-1 sm:grid-cols-3 gap-6 mt-10 mb-16",
          },
          [
            _c("div", { staticClass: "col-span-1 mx-3" }, [
              _c("img", {
                staticClass: "w-full h-48 py-2",
                attrs: { src: require("@/assets/image/top/top_icon_news.svg") },
              }),
              _c("h5", { staticClass: "font-semibold" }, [_vm._v("NEWS")]),
              _c("p", { staticClass: "my-3" }, [
                _vm._v(" " + _vm._s(_vm.$t("pages.tops.whats.news")) + " "),
              ]),
              _c(
                "a",
                {
                  staticClass:
                    "w-full h-full text-center hover:bg-off-yellow-lighter active:bg-off-yellow-darker px-2 py-2 bg-light-yellow",
                  attrs: { href: _vm.newsLink, target: "_blank" },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("pages.tops.whats.readmore")) + " "
                  ),
                ]
              ),
            ]),
            _c("div", { staticClass: "col-span-1 mx-3" }, [
              _c("img", {
                staticClass: "w-full h-48 py-2",
                attrs: {
                  src: require("@/assets/image/top/top_icon_interview.svg"),
                },
              }),
              _c("h5", { staticClass: "font-semibold" }, [_vm._v("INTERVIEW")]),
              _c("p", { staticClass: "my-3" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("pages.tops.whats.interview")) + " "
                ),
              ]),
              _c(
                "a",
                {
                  staticClass:
                    "w-full h-full text-center hover:bg-off-yellow-lighter active:bg-off-yellow-darker px-2 py-2 bg-light-yellow",
                  attrs: { href: _vm.interviewLink, target: "_blank" },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("pages.tops.whats.readmore")) + " "
                  ),
                ]
              ),
            ]),
            _c("div", { staticClass: "col-span-1 mx-3" }, [
              _c("img", {
                staticClass: "w-full h-48 py-2",
                attrs: {
                  src: require("@/assets/image/top/top_icon_aboutus.svg"),
                },
              }),
              _c("h5", { staticClass: "font-semibold" }, [_vm._v("ABOUT US")]),
              _c("p", { staticClass: "my-3" }, [
                _vm._v(" " + _vm._s(_vm.$t("pages.tops.whats.aboutus")) + " "),
              ]),
              _c(
                "a",
                {
                  staticClass:
                    "w-full h-full text-center hover:bg-off-yellow-lighter active:bg-off-yellow-darker px-2 py-2 bg-light-yellow",
                  attrs: { href: _vm.aboutusLink, target: "_blank" },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("pages.tops.whats.readmore")) + " "
                  ),
                ]
              ),
            ]),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }