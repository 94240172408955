var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "modal",
        {
          attrs: {
            name: _vm.modalName,
            width: "50%",
            height: "auto",
            "max-height": 550,
            scrollable: true,
            adaptive: true,
          },
          on: { "before-close": _vm.beforeClose },
        },
        [
          _c("div", { staticClass: "relative" }, [
            _c(
              "button",
              {
                staticClass: "close_btn h-3 w-3 float-left absolute",
                on: {
                  click: function ($event) {
                    return _vm.$modal.hide(_vm.modalName)
                  },
                },
              },
              [
                _c("img", {
                  staticClass: "close_btn h-3 w-3",
                  attrs: { src: require("@/assets/image/close_icon_gray.svg") },
                }),
              ]
            ),
          ]),
          _c(
            "div",
            { staticClass: "w-7/12 mx-auto my-4" },
            [_vm._t("default")],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }