var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.currentOpenModalName == _vm.modalName || _vm.keepOpen
    ? _c(
        "div",
        {
          staticClass: "border-l border-black bg-transparent z-10",
          class: _vm.classList,
          on: {
            mouseover: function ($event) {
              return _vm.changeOpenStatus(true)
            },
            mouseleave: function ($event) {
              return _vm.changeOpenStatus(false)
            },
            click: _vm.closeModal,
          },
        },
        [_vm._t("default")],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }