var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("label", { staticClass: "cursor-pointer inline-block mb-1" }, [
    _c("input", {
      key: _vm.inputKey,
      staticClass:
        "rounded-sm w-5 h-5 disabled:bg-brown-grey text-white inline-block relative align-middle",
      attrs: { type: "checkbox", id: _vm.uniqueId, disabled: _vm.disabled },
      domProps: {
        value: _vm.value,
        checked: _vm.isChecked,
        indeterminate: _vm.isIndeterminate,
      },
      on: { change: _vm.input },
    }),
    _c(
      "div",
      {
        staticClass: "inline relative align-middle",
        attrs: { for: _vm.uniqueId, disabled: _vm.disabled },
      },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }