var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container mx-auto" },
    [
      _c(
        "modal",
        {
          attrs: {
            name: _vm.modalName,
            width: "50%",
            height: "auto",
            "max-height": 550,
            "min-width": 320,
            scrollable: true,
            adaptive: true,
            styles: "border-radius: 0.5rem",
          },
          on: {
            "before-open": _vm.beforeOpen,
            "before-close": _vm.beforeClose,
          },
        },
        [
          _c("div", { staticClass: "relative" }, [
            _c(
              "button",
              {
                staticClass: "absolute top-2 right-2",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.$modal.hide(_vm.modalName)
                  },
                },
              },
              [
                _c("img", {
                  staticClass: "h-4 w-4",
                  attrs: { src: require("@/assets/image/close_icon_gray.svg") },
                }),
              ]
            ),
          ]),
          _c(
            "div",
            { staticClass: "mx-10 mt-8 mb-4" },
            [
              _vm.title
                ? _c("h2", { staticClass: "text-center mb-10" }, [
                    _vm._v(_vm._s(_vm.title)),
                  ])
                : _vm._e(),
              _vm.contents
                ? _c("p", { staticClass: "mb-6 font-bold text-center" }, [
                    _vm._v(_vm._s(_vm.contents)),
                  ])
                : _vm._e(),
              _vm._t("default"),
              _vm.buttons.length
                ? _c(
                    "div",
                    {
                      staticClass: "w-full mx-auto flex mt-4",
                      class: [
                        _vm.buttons.length > 1
                          ? "justify-between"
                          : "justify-center",
                      ],
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass:
                            "px-2 py-2 w-1/3 h-full relative text-center font-bold",
                          class: [
                            _vm.buttons.length > 1
                              ? "bg-brown-grey"
                              : "bg-base-yellow",
                          ],
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.$modal.hide(_vm.modalName)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.buttons[0]) + " ")]
                      ),
                      _vm.buttons.length > 1
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "px-2 py-2 w-1/3 h-full relative text-center bg-base-yellow font-bold",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.clickButton()
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.buttons[1]) + " ")]
                          )
                        : _vm._e(),
                    ]
                  )
                : _c(
                    "div",
                    { staticClass: "w-full x-auto flex mt-4" },
                    _vm._l(_vm.actionButtons, function (button, key, index) {
                      return _c(
                        "button",
                        {
                          key: index,
                          staticClass:
                            "px-2 py-2 w-1/3 h-full relative text-center font-bold rounded",
                        },
                        [_vm._v(" " + _vm._s(key) + " ")]
                      )
                    }),
                    0
                  ),
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }