var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationProvider", {
    staticClass: "w-full",
    attrs: {
      name: _vm.labels,
      tag: "div",
      rules: _vm.rules,
      immediate: _vm.immediate,
    },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function (ref) {
            var errors = ref.errors
            return [
              _vm.label || _vm.sublabel
                ? _c("div", { staticClass: "w-full inline-block -mb-1" }, [
                    _c("span", { staticClass: "float-left text-xs" }, [
                      _vm._v(_vm._s(_vm.label)),
                    ]),
                    _c("span", { staticClass: "float-right text-xs" }, [
                      _vm._v(_vm._s(_vm.sublabel)),
                    ]),
                  ])
                : _vm._e(),
              _vm._t("default", null, { errors: errors }),
              errors.length && _vm.showError
                ? _c(
                    "p",
                    {
                      staticClass:
                        "text-left text-red-600 text-xxs pt-px font-bold",
                    },
                    [_vm._v(_vm._s(errors[0]))]
                  )
                : !_vm.hideNoError
                ? _c("p", { staticClass: "h-4" })
                : _vm._e(),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }